import { cateringRequest, cateringRequestHide, cateringUploadsHide } from '@/utils/fetch'

interface saveReq {
  id?: string
  name: string //项目名称
  belong_area: number // 所属事业部 区域
  tender: number // 投标方式
  tender_organ: number // 投标机构
  province_id: string //项目地址_省id
  city_id: string //项目地址_市id
  area_id: string //项目地址_区id
  type_id: string //项目类别：厨具1，网络2，广告3，餐饮4，超市5，配送6，职校7,当项目为综合项目时可多选
  is_comprehensive: string //是否为综合项目（0不是，1是）
  declare_type: string //	申报类型：1个人业务，2公司指派，3外部协作
  capital_source: string //资金来源：1政府资金，2自筹资金
  project_size: string //	项目规模：多少人
  contact_name: string //	联系人：姓名
  contact_phone: string //	联系人：电话
  project_type?: string //工程类型：1新建，2改造
  mode_operation?: string //经营方式：1自营，2托管，3劳务
  inspection_instructions: string //	考察说明
  introduce_staff_name?: string //	介绍人
  collaboration_staff_name?: string //	协作人
  application_unit?: string //	申报单位
  staff_id?: string //	申报员工ID
  staff_name?: string //	申报员工姓名
  staff_bumen?: string //	申报员工部门
  staff_zhiwu?: string //申报员工职务
  address?: string //  项目详细地址
  person_type: string // 项目 子类别：厨具1，网络2，广告3，餐饮4，超市5，配送6，职校7,当项目为综合项目时可多选
}

export type saveProject = saveReq //导出请求保存的数据结构

// 项目申报 添加接口
export function declareSave(data: saveReq) {
  const url = '/api/project/save'
  return cateringRequest.post(url, data)
}
